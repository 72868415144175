export enum I18nsEnum {
  'zh-CN' = 'zh-CN',
  'zh-HK' = 'zh-HK',
  'en-US' = 'en-US',
  'ta-IN' = 'ta-IN',
  'hi-IN' = 'hi-IN',
  'pt-BR' = 'pt-BR',
  'vi-VN' = 'vi-VN',
  'ko-KR' = 'ko-KR',
  'en-ID' = 'en-ID',
  'th-TH' = 'th-TH',
  'ja-JP' = 'ja-JP',
}
// 机翻的多个目标语言
export const targetLanguages = {
  'ta-IN': 'ta',
  'hi-IN': 'hi',
  'pt-BR': 'pt',
  'vi-VN': 'vi',
  'ko-KR': 'ko',
  'en-ID': 'id',
  'th-TH': 'th',
  'ja-JP': 'ja',
}
export const I18nKeys = Object.values(I18nsEnum)

export enum I18nsEnumAll {
  'zh-CN' = 'zh-CN',
  'zh-HK' = 'zh-HK',
  'en-US' = 'en-US',
  'zh-cn' = 'zh-CN',
  'zh-hk' = 'zh-HK',
  'en-us' = 'en-US',
  'ta-in' = 'ta-IN',
  'hi-in' = 'hi-IN',
  'pt-br' = 'pt-BR',
  'vi-vn' = 'vi-VN',
  'ko-kr' = 'ko-KR',
  'en-id' = 'en-ID',
  'th-th' = 'th-TH',
  'ja-jp' = 'ja-JP',
  'ta-IN' = 'ta-IN',
  'hi-IN' = 'hi-IN',
  'pt-BR' = 'pt-BR',
  'vi-VN' = 'vi-VN',
  'ko-KR' = 'ko-KR',
  'en-ID' = 'en-ID',
  'th-TH' = 'th-TH',
  'ja-JP' = 'ja-JP',
}

export const languageRoutes = I18nKeys.map(v => `/${v}`)

export const I18nsMap = {
  [I18nsEnum['en-US']]: 'English',
  [I18nsEnum['zh-CN']]: '简体中文',
  [I18nsEnum['zh-HK']]: '繁體中文',
  // [I18nsEnum['ta-IN']]: 'ದಕ್ಷಿಣ ಭಾರತ',
  // [I18nsEnum['hi-IN']]: 'उत्तर भारत',
  // [I18nsEnum['pt-BR']]: 'português brasileiro',
  // [I18nsEnum['vi-VN']]: 'Tiếng Việt',
  // [I18nsEnum['ko-KR']]: '한국어',
  // [I18nsEnum['en-ID']]: 'Bahasa Indonesia',
  // [I18nsEnum['th-TH']]: 'ภาษาไทย',
  // [I18nsEnum['ja-JP']]: '日本語',
}
export function getI18nEmptyObject() {
  let res = {}
  I18nKeys.forEach(k => {
    res[k] = {}
  })
  return res
}

// 国旗图标映射
export const areaCodeImageMap = key =>
  ({
    'zh-CN': ['简体中文', 'CN.png'],
    'zh-HK': ['繁體中文', 'HK.png'],
    'en-US': ['English', 'US.png'],
    'ta-IN': ['English', 'US.png'],
    'hi-IN': ['English', 'US.png'],
    'pt-BR': ['English', 'US.png'],
    'vi-VN': ['English', 'US.png'],
    'ko-KR': ['English', 'US.png'],
    'en-ID': ['English', 'US.png'],
    'th-TH': ['English', 'US.png'],
    'ja-JP': ['English', 'US.png'],
  }[key || 'zh-CN'])

// Logo 图标映射
// export const LogoMap = key =>
//   ({
//     'zh-CN': 'zh-cn.gif',
//     'zh-HK': 'zh-cn.gif',
//     'en-US': 'en.gif',
//   }[key || 'zh-CN'])

// Logo Svg 图标映射
export const LogoSvgMap = key =>
  ({
    'zh-CN': 'zh-cn2.svg',
    'zh-HK': 'zh-cn2.svg',
    'en-US': 'en1.svg',
    'ta-IN': 'en1.svg',
    'hi-IN': 'en1.svg',
    'pt-BR': 'en1.svg',
    'vi-VN': 'en1.svg',
    'ko-KR': 'en1.svg',
    'en-ID': 'en1.svg',
    'th-TH': 'en1.svg',
    'ja-JP': 'en1.svg',
  }[key || 'zh-CN'])

export const LanguageMapToChartMap = {
  'zh-CN': 'zh',
  'en-US': 'en',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'th-TH': 'th_TH',
  'zh-TW': 'zh_TW',
  'fr-FR': 'fr',
  'de-DE': 'de_DE',
  'it-IT': 'it',
  'es-ES': 'es',
  'vi-VN': 'vi',
}
