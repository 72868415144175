import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { createTrackedSelector } from 'react-tracked'
import { ThemeEnum } from '@/constants/base'
import { setCookieLocale, setCookieTheme } from '@/helper/cookie'
import { getLangCache, getThemeCache, setLangCache, setThemeCache, setThemeTypeCache } from '@/helper/cache'
import { I18nsEnum } from '@/constants/i18n'
import { t } from '@lingui/macro'
import produce from 'immer'
import { systemThemeColor } from '@/helper/env'

type IStore = ReturnType<typeof getStore>

const themeCache = getThemeCache()
const langCache = getLangCache()

const menuIndex = 0 // 我的账户菜单栏默认下标

function getStore(set, get) {
  return {
    theme: themeCache || ThemeEnum.light,
    themeType: systemThemeColor,
    setThemeType: (currentThemeType?: string) =>
      set(state => {
        setThemeTypeCache(currentThemeType)
        return { themeType: currentThemeType }
      }),
    setTheme: (currentTheme?: string) =>
      set((state: IStore) => {
        if (currentTheme) {
          setThemeCache(currentTheme)
          return { theme: currentTheme }
        }
        currentTheme = state.theme === ThemeEnum.dark ? ThemeEnum.light : ThemeEnum.dark
        setThemeCache(currentTheme)
        return { theme: currentTheme }
      }),
    locale: langCache || I18nsEnum['en-US'],
    setLocale: (currentLocale?: string) =>
      set(() => {
        if (currentLocale) {
          return { locale: currentLocale }
        }
        return {}
      }),
    secretKey: null,
    setSecretKey: (secretKey?: string) =>
      set(() => {
        if (secretKey) {
          return { secretKey }
        }
        return {}
      }),
    menuName: menuIndex, // 我的账户菜单栏下标
    setMenuName: (values?: number) =>
      set((store: IStore) => {
        return produce(store, _store => {
          _store.menuName = values as number
        })
      }),
  }
}

const baseCommonStore = create(subscribeWithSelector(getStore))

baseCommonStore.subscribe(
  state => state.themeType,
  themeType => {
    if (typeof window !== 'undefined') {
      document.body.setAttribute('theme-type', themeType)
    }
  }
)

// 添加监听，A 模块变动去修改 B 模块状态
baseCommonStore.subscribe(
  state => state.theme,
  theme => {
    if (typeof window !== 'undefined') {
      document.body.setAttribute('arco-theme', theme)
      setCookieTheme(theme)
    }
  }
)
baseCommonStore.subscribe(
  state => state.locale,
  locale => {
    if (typeof window !== 'undefined') {
      setLangCache(locale)
      setCookieLocale(locale)
    }
  }
)

const useCommonStore = createTrackedSelector(baseCommonStore)
// TODO: 必须要这样调用：baseCommonStore.getState()
export { useCommonStore, baseCommonStore }
